<template>
    <span class="sr-only">The July</span>
    <svg
        class="the-logo"
        fill="currentColor"
        viewBox="0 0 207 44"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="currentColor">
            <path d="m13.3909 34.1813v-22.0431h-8.58817v-2.72536h20.15597v2.72536h-8.5874v22.0433z" />
            <path d="m31.877 34.1811v-24.76862h2.9804v10.65132h15.259v-10.65132h2.9804v24.76862h-2.9804v-11.3932h-15.259v11.3932z" />
            <path d="m61.79 34.1811v-24.76862h16.9616v2.72542h-14.0165v7.8193h12.5971v2.6534h-12.5971v8.8463h14.3366v2.7241h-17.2817z" />
            <path d="m110.943 27.5764v-3.5406h2.947v3.5051c0 2.5494 2.166 4.32 5.255 4.32 2.983 0 5.043-1.912 5.043-4.674v-17.77442h2.983v17.84552c0 4.3195-3.267 7.3292-8.026 7.3292-4.829 0-8.202-2.8681-8.202-7.0108z" />
            <path d="m135.442 25.877v-16.46452h2.983v16.39382c0 3.9301 3.125 6.0193 6.925 6.0193 3.799 0 6.924-2.0892 6.924-6.0193v-16.39382h2.983v16.46452c0 4.7091-3.516 8.7456-9.907 8.7102-6.428 0-9.908-4.0011-9.908-8.7102z" />
            <path d="m163.742 34.1978v-24.78532h2.983v22.05892h13.316v2.7264z" />
            <path d="m189.734 34.1978v-10.9409l-9.446-13.84442h3.516l7.457 11.01192 7.421-11.01192h3.516l-9.481 13.84442v10.9409z" />
        </g>
    </svg>
</template>

<style lang="less">
.the-logo {
    display: block;
    margin: 0 auto;
    height: 1.5rem;

    @media @q-md-min {
        height: 2.75rem;
    }

    path {
        fill: currentColor;
    }
}
</style>
